import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './themeConfig';
import './App.css';
import './styles/style1.css';
// import './styles/style2.css';
// import './styles/style3.css';
// import './styles/style4.css';
// import './styles/style5.css';
// import './styles/style6.css';
// import './styles/style7.css';

// pages
// import ComingSoon from './pages/ComingSoon';
import Home from './pages/Home';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolitics from './pages/PrivacyPolitics';
import NewHome from './pages/NewHome';
import NewPricePlan from './pages/NewPricePlan';
import FAQ from './pages/FAQ';
import BlogsPage from './pages/BlogPages';
import BlogInfoPage from './pages/BlogInfo';
import TemplatesPage from './pages/Templates';
import NewComingSoon from './pages/NewComingSoon';
import Calendar from './pages/Calendar';
import DocumentationPlatformPage from './pages/DocumentationPlatform';
import DocumentationEditorPage from './pages/DocumentationEditor';
import PreviewTemplate from './pages/previewtemplate';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route path="/terms" component={TermsConditions} />
          <Route path="/privacy" component={PrivacyPolitics} />
          <Route path="/faq" component={FAQ} />
          <Route path="/prices" component={NewPricePlan} />
          <Route path="/blogs" component={BlogsPage} />
          <Route path="/bloginfo/:_id" component={BlogInfoPage} />
          {/* <Route path="/documentation" component={NewComingSoon} /> */}
          <Route path="/blogs" component={NewComingSoon} />
          <Route path="/templates" component={TemplatesPage} />
          <Route path="/calendar" component={Calendar} />
          <Route path="/documentation/platform" component={DocumentationPlatformPage} />
          <Route path="/documentation/editor" component={DocumentationEditorPage} />
          <Route path="/previewtemplate/:idTemplate" component={PreviewTemplate} />
          <Route exact path="/" component={NewHome} />
          <Route path="*" component={Home} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
