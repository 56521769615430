import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, IconButton, Menu, MenuItem, Box } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '../../img/newhome/logo.png';
import { Grid, Typography, Container,Button,  } from '@mui/material';

const TopBar = () => {
  const handleLoginClick = () => {
    window.location.href = 'https://platform.loftyapps.com/';
  };

  const handleRegisterClick = () => {
    window.location.href = 'https://platform.loftyapps.com/register';
  };
  return (
    <div style={{
      backgroundColor: '#001944',
      color: 'white',
      padding: '20px 40px',
      borderBottom: '2px solid #14569e'
    }}>
      <Container maxWidth="xl">
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body1" style={{
              color: 'white',
              fontSize: '16px',
              fontFamily: 'Roboto, sans-serif',
              fontWeight: '400',
              lineHeight: '23.44px',
            }}>
              Inicia sesión o regístrate para disfrutar de todos los beneficios.
            </Typography>
          </Grid>
          <Grid item>
            <Button variant="outlined" style={{
              color: 'white',
              borderColor: 'white',
              marginRight: '10px',
              fontSize: '11px',
              padding: '6px 12px',
            }}
            onClick={handleLoginClick}
            >
              INICIAR SESIÓN
            </Button>
            <Button variant="contained" style={{
              backgroundColor: '#29B2EF',
              color: '#201549',
              fontSize: '11px',
              padding: '6px 12px',
            }}
            onClick={handleRegisterClick}
            >
              REGÍSTRATE
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};




const items = [
  {
    name: 'home',
    label: 'Inicio',
    url: '/'
  },
  {
    name: 'price',
    label: 'Precios',
    url: '/prices'
  },
  {
    name: 'questions',
    label: 'Preguntas frecuentes',
    url: '/faq'
  },
  {
    name: 'templates',
    label: 'Plantillas',
    url: '/templates'
  },
  /* {
    name: 'blog',
    label: 'Blog',
    url: '/blogs'
  },
  {
    name: 'documentation',
    label: 'Documentación',
    url: '/documentation'
  }, */
]

const Navbar2 = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const openAnchor = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <nav className='container-navbar2-loftyapps'>
       <TopBar />
      <div className='navbar2-loftyapps'>
        <div>
          <Link to="/">
            <img alt='Logo Lofty Apps' className="logo2-loftyapps" src={logo} />
          </Link>
        </div>
        <div className='button-menu-loftyapps'>
          <IconButton onClick={() => setOpen(!open)}>
            <MenuIcon color='#292151' />
          </IconButton>
        </div>
        <div className='item-navbar2-loftyapps'>
          {
            items.map((item) => (
              <Link className="link-navbar2-loftyapps" to={item.url} key={item.name}>
                {item.label}
              </Link>
            ))
          }
          <div className='link-navbar2-loftyapps'>
            <Box
              id="basic-button"
              aria-controls={openAnchor ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openAnchor ? 'true' : undefined}
              onMouseEnter={handleClick}
              className='link-navbar2-loftyapps'
              sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}
            >
              Documentación
              {openAnchor ? (
                  <KeyboardArrowDownIcon />
                ) : (
                <KeyboardArrowUpIcon />
              )}
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              keepMounted
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              onMouseLeave={handleClose}
            >
              <MenuItem>
                <Link className="link-navbar2-loftyapps" to={'/documentation/platform'}>
                  Plataforma
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className="link-navbar2-loftyapps" to={'/documentation/editor'}>
                  Editor
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </div>
      <div className="text-navbar2-loftyapps">
        <div>
          ¿Te imaginas la herramienta de software perfecta para tu empresa pero no sabes codificar?
          {' '}
          <Link to="/calendar" className="link2-navbar2-loftyapps" >
            Agenda una reunión
          </Link>
        </div>
      </div>
      <Drawer
        anchor='left'
        open={open}
        onClose={() => setOpen(!open)}
      >
        <div style={{ width: 280 }}>
          {
            items.map((item) => (
              <div style={{ textAlign: 'center', marginTop: 40, marginBottom: 40 }} key={item.name}>
                <Link className="link-navbar2-loftyapps" to={item.url}>
                  {item.label}
                </Link>
              </div>
            ))
          }
          <div className='link-navbar2-loftyapps' style={{ textAlign: 'center', marginTop: 40, marginBottom: 40 }}>
            <Box
              id="basic-button"
              aria-controls={openAnchor ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={openAnchor ? 'true' : undefined}
              onClick={handleClick}
              className='link-navbar2-loftyapps'
              sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}
            >
              Documentación
              {openAnchor ? (
                <KeyboardArrowDownIcon />
                ) : (
                <KeyboardArrowUpIcon />
              )}
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openAnchor}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              onMouseLeave={handleClose}
            >
              <MenuItem>
                <Link className="link-navbar2-loftyapps" to={'/documentation/platform'}>
                  Plataforma
                </Link>
              </MenuItem>
              <MenuItem>
                <Link className="link-navbar2-loftyapps" to={'/documentation/editor'}>
                  Editor
                </Link>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </Drawer>
    </nav>
  )
};

export default Navbar2;
